import React, {useState, useContext, useEffect} from 'react';
import {
  sortBy,
  isEmpty,
  some,
  every,
  uniqBy,
} from 'lodash';
import cx from 'classnames';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';

import {useAnalytics} from '../../hooks';
import {CourseLessonsContext} from '../../components/CourseLessonsProvider';

import Link from '../../components/Link';
import FadeOut from '../../components/FadeOut';
import CollapserThing from '../../components/CollapserThing';
import {
  CaretIcon,
  ActiveEmptyImage,
  CompletedEmptyImage,
  SavedEmptyImage,
  BookmarkIcon,
} from '../../components/svg';

const MAX_MY_PROGRESS = 50;

const Tabs = {
  active: 'Active',
  completed: 'Completed',
  saved: 'Saved',
};

const Tab = ({isCurrent, text, onClick}) => (
  <button
    className={cx('items-center justify-center sm:justify-start px-3 py-2 sm:w-auto border-b-2 text-xs font-semibold uppercase', {
      'border-indigo text-indigo': isCurrent,
      'border-transparent text-gray-400 hover:border-gray-300': !isCurrent,
    })}
    type="button"
    onClick={onClick}
  >
    {text}
  </button>
);

const ProgressCircle = ({percent}) => (
  <div className="w-5 flex-shrink-0">
    <div
      className="relative w-full rounded-full flex items-center justify-center"
      style={{
        paddingBottom: '100%',
        backgroundImage: `conic-gradient(#0FB059 ${percent}%, #eee ${percent}%)`,
      }}
    >
      <div
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full"
        style={{
          paddingRight: '70%',
          paddingBottom: '70%',
        }}
      />
    </div>
  </div>
);

const ActiveRow = ({progress, onClick}) => (
  <>
    <ProgressCircle percent={progress.percentComplete} />
    <Link
      href={`/courses/${progress.slug}`}
      className="inline-block text-sm"
      onClick={onClick}
    >
      {progress.shortTitle}
    </Link>
  </>
);

const CompletedRow = ({progress, onClick}) => (
  <>
    <FontAwesomeIcon className="w-6 h-5 fill-current text-gray-400" icon={faCheckCircle} />
    <Link
      href={`/courses/${progress.slug}`}
      className="text-sm text-gray-500"
      onClick={onClick}
    >
      {progress.shortTitle}
    </Link>
  </>
);

const SavedRow = ({progress, onClick}) => (
  <>
    <div className="w-5 flex-shrink-0">
      <BookmarkIcon />
    </div>
    <Link
      href={progress.lessonURL}
      className="inline-block text-sm"
      onClick={onClick}
    >
      {progress.shortTitle}
    </Link>
  </>
);

const MyCourseRow = ({currentTab, progress}) => {
  const {tracker} = useAnalytics();

  const onClick = () => {
    tracker.dashboardClickMyCourse({type: currentTab});
  };

  return (
    <div className="flex flex-row items-center space-x-4">
      {currentTab === Tabs.active && <ActiveRow progress={progress} onClick={onClick} />}
      {currentTab === Tabs.completed && <CompletedRow progress={progress} onClick={onClick} />}
      {currentTab === Tabs.saved && <SavedRow progress={progress} onClick={onClick} />}
    </div>
  );
};

const MyCoursesCol = ({
  currentTab, myProgress, isEmptyState,
}) => {
  const currentImage = {
    [Tabs.active]: <ActiveEmptyImage width="100%" height="130" />,
    [Tabs.completed]: <CompletedEmptyImage width="100%" height="130" />,
    [Tabs.saved]: <SavedEmptyImage width="100%" height="130" />,
  };

  const currentMessage = {
    [Tabs.active]: 'You don\'t have any active courses at the moment',
    [Tabs.completed]: 'You don\'t have any completed courses yet',
    [Tabs.saved]: 'You don\'t have any saved courses yet',
  };

  return (isEmpty(myProgress)
    ? (
      <div className="flex flex-col justify-center items-center mt-3">
        {currentImage[currentTab]}
        <div className="mt-1 mx-3 text-sm text-gray-500 text-center">
          {currentMessage[currentTab]}
        </div>
      </div>
    ) : (
      <FadeOut top bottom height="h-3">
        <div className={cx('overflow-y-auto py-3 max-h-44', {'h-44': isEmptyState})}>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-3">
            {myProgress.slice(0, MAX_MY_PROGRESS).map((progress) => (
              <MyCourseRow
                currentTab={currentTab}
                progress={progress}
                key={progress.shortTitle}
              />
            ))}
          </div>
        </div>
      </FadeOut>
    )
  );
};

const MyCourses = () => {
  const {tracker} = useAnalytics();
  const {courses} = useContext(CourseLessonsContext);
  const [showMyCourses, setShowMyCourses] = useState(true);
  const [currentTab, setCurrentTab] = useState(Tabs.active);
  const [tabs, setTabs] = useState({});

  useEffect(() => {
    const active = [];
    const complete = [];
    let saved = [];

    courses.forEach((course) => {
      const isComplete = every(course.lessons, 'lastVisit');
      const isActive = !isComplete && some(course.lessons, 'lastVisit');

      if (isActive) active.push(course);
      if (isComplete) complete.push(course);

      if (course.contentType === 'course' && course.lessons?.length > 0) {
        const savedLessons = course.lessons
          .filter((lesson) => lesson.savedAt)
          .map((lesson) => ({
            ...lesson,
            lessonURL: `/courses/${course.slug}/${lesson.slug}`,
          }));
        saved = saved.concat(savedLessons);
      }
    });

    saved = uniqBy(saved, 'slug');

    setTabs({
      [Tabs.active]: sortBy(active, [(o) => -o.percentComplete]),
      [Tabs.completed]: sortBy(complete, [(o) => -o.lastVisit]),
      [Tabs.saved]: sortBy(saved, [(o) => -o.lastVisit]),
    });

    setShowMyCourses(!JSON.parse(localStorage.getItem('collapseMyCourses')));
  }, []);

  const toggleMyCourses = () => {
    if (showMyCourses) tracker.dashboardCollapseMyCourses();
    localStorage.setItem('collapseMyCourses', showMyCourses);
    setShowMyCourses(!showMyCourses);
  };

  const hasProgress = (
    tabs[Tabs.active]?.length
    || tabs[Tabs.completed]?.length
    || tabs[Tabs.saved]?.length
  );

  return hasProgress ? (
    <>
      <hr className="border border-gray-100 my-2" />
      <button
        className="block flex flex-row justify-between items-center w-full"
        type="button"
        onClick={toggleMyCourses}
      >
        <div className="font-semibold">My Progress</div>
        <CaretIcon
          height={9}
          width={6}
          fill="black"
          className={cx('mr-1 transform -rotate-180 transition duration-200', {'-rotate-90': showMyCourses})}
        />
      </button>
      <CollapserThing visible={showMyCourses}>
        <div className="mt-1 border-b border-gray-300 flex flex-row justify-around">
          {Object.keys(Tabs).map((tab) => (
            <Tab
              text={tab}
              isCurrent={currentTab === Tabs[tab]}
              onClick={() => setCurrentTab(Tabs[tab])}
            />
          ))}
        </div>
        <MyCoursesCol
          currentTab={currentTab}
          myProgress={tabs[currentTab]}
          isEmptyState={isEmpty(tabs[currentTab])}
        />
      </CollapserThing>
      <hr className="border border-gray-100 mt-3" />
    </>
  ) : null;
};

export default MyCourses;
