import {invert} from 'lodash';

/**
 * This file should stay in sync with the credits
 * constants in src/server/constants/streaks.js
 */

// eslint-disable-next-line import/prefer-default-export
export const StreakTypes = {
  DAILY_CHALLENGE: 'daily_challenge',
};

export const StreakTypesValues = invert(StreakTypes);
