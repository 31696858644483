import React from 'react';
import cx from 'classnames';
import _ from 'lodash';

// Can't dynamically create utility classes because they'll be purged by Tailwind's PurgeCSS
const Widths = {
  xs: ['xs:w-0', 'xs:w-1/12', 'xs:w-2/12', 'xs:w-3/12', 'xs:w-4/12', 'xs:w-5/12', 'xs:w-6/12', 'xs:w-7/12', 'xs:w-8/12', 'xs:w-9/12', 'xs:w-10/12', 'xs:w-11/12', 'xs:w-full'],
  sm: ['sm:w-0', 'sm:w-1/12', 'sm:w-2/12', 'sm:w-3/12', 'sm:w-4/12', 'sm:w-5/12', 'sm:w-6/12', 'sm:w-7/12', 'sm:w-8/12', 'sm:w-9/12', 'sm:w-10/12', 'sm:w-11/12', 'sm:w-full'],
  md: ['md:w-0', 'md:w-1/12', 'md:w-2/12', 'md:w-3/12', 'md:w-4/12', 'md:w-5/12', 'md:w-6/12', 'md:w-7/12', 'md:w-8/12', 'md:w-9/12', 'md:w-10/12', 'md:w-11/12', 'md:w-full'],
  lg: ['lg:w-0', 'lg:w-1/12', 'lg:w-2/12', 'lg:w-3/12', 'lg:w-4/12', 'lg:w-5/12', 'lg:w-6/12', 'lg:w-7/12', 'lg:w-8/12', 'lg:w-9/12', 'lg:w-10/12', 'lg:w-11/12', 'lg:w-full'],
  xl: ['xl:w-0', 'xl:w-1/12', 'xl:w-2/12', 'xl:w-3/12', 'xl:w-4/12', 'xl:w-5/12', 'xl:w-6/12', 'xl:w-7/12', 'xl:w-8/12', 'xl:w-9/12', 'xl:w-10/12', 'xl:w-11/12', 'xl:w-full'],
  xxl: ['2xl:w-0', '2xl:w-1/12', '2xl:w-2/12', '2xl:w-3/12', '2xl:w-4/12', '2xl:w-5/12', '2xl:w-6/12', '2xl:w-7/12', '2xl:w-8/12', '2xl:w-9/12', '2xl:w-10/12', '2xl:w-11/12', '2xl:w-full'],
};

export const Grid = ({className, children}) => {
  const classes = cx('', className);
  return <div className={classes}>{children}</div>;
};

export const Row = ({className, children}) => {
  const classes = cx(`
    flex
    flex-wrap
  `, className);
  return <div className={classes}>{children}</div>;
};

const makeWidth = (size, num) => Widths[size][num];

export const Col = ({
  sm, md, lg, xl, xxl, className, children,
}) => {
  const classes = cx(`
    flex
    flex-col
    flex-grow
    w-full
    px-3
  `,
  {
    [makeWidth('sm', sm)]: _.isInteger(sm),
    [makeWidth('md', md)]: _.isInteger(md),
    [makeWidth('lg', lg)]: _.isInteger(lg),
    [makeWidth('xl', xl)]: _.isInteger(xl),
    [makeWidth('xxl', xxl)]: _.isInteger(xxl),
  },
  className);
  return <div className={classes}>{children}</div>;
};
