import React from 'react';
import _ from 'lodash';

import {getCourseThumbnail, getLessonThumbnail} from '../../util';
import {useAnalytics} from '../../hooks';

import Link from '../../components/Link';
import Button from '../../components/Button';
import Card from '../../components/Card';
import LessonThumbnail from '../../components/LessonThumbnail';
import MyCourses from './MyCourses';

const getCourseSubtitle = (course) => {
  if (course.contentType === 'track') {
    return course.courses?.length ? `${course.courses.length} courses` : '';
  }
  return course?.lessons?.length ? `${course.lessons.length} lessons` : '';
};

const CoursesCard = ({loading = true, courses = [], suggestedLesson = null}) => {
  const {tracker} = useAnalytics();

  if (loading) return <Placeholder />;

  let numLessons;
  let numComplete;
  if (suggestedLesson) {
    try {
      numLessons = suggestedLesson.course.lessons?.length || 0;
      numComplete = _.sumBy(suggestedLesson.course.lessons, 'markedAsComplete');
    } catch (err) {
      console.error('Unable to calculate course progress');
    }
  }

  return (
    <Card>
      <span className="block text-xs font-semibold text-gray-400 uppercase">Courses</span>
      {suggestedLesson ? (
        <>
          <span className="text-lg font-semibold mb-1">Pick up where you left off</span>
          <span className="block text-gray-500 leading-snug max-w-md">
            {`Keep it up! ${numLessons && numLessons > numComplete
              ? `You only have ${numLessons - numComplete} lesson${numLessons - numComplete > 1 ? 's' : ''} remaining in this course.`
              : 'You\'re making good progress through your course.'}`}
          </span>
          <CourseRow
            href={suggestedLesson.path}
            imageUrl={getLessonThumbnail(suggestedLesson)}
            description={(
              <>
                <span className="block text-lg font-medium leading-tight">{suggestedLesson.shortTitle}</span>
                <span className="block text-gray-500 leading-tight">
                  {_.truncate(suggestedLesson.description, {length: 100})}
                </span>
              </>
            )}
            progress={(numComplete / numLessons) * 100}
            ctaText="Continue"
          />
        </>
      ) : (
        <>
          <span className="text-lg font-semibold mb-1">Start a new course</span>
          <span className="block text-gray-500 leading-snug max-w-sm">
            Here are our recommended courses based on your career goals.
          </span>
          {courses.map((course) => (
            <CourseRow
              key={course.slug}
              href={`/courses/${course.slug}`}
              imageUrl={getCourseThumbnail(course)}
              description={(
                <>
                  <span className="block text-xs font-semibold text-gray-400 uppercase">
                    Recommended
                  </span>
                  <span className="block text-lg font-medium leading-tight">
                    {course.shortTitle}
                  </span>
                  <span className="block text-gray-500 leading-tight">
                    {getCourseSubtitle(course)}
                  </span>
                </>
              )}
              ctaText="Start course"
            />
          ))}
        </>
      )}
      <MyCourses />
      <Link
        href="/courses?src=dashboard"
        onClick={() => tracker.dashboardClickMoreCourses()}
        className="flex justify-center pt-5 text-indigo text-sm font-medium hover:text-indigo-900 focus:text-indigo-900"
      >
        See more courses
      </Link>
    </Card>
  );
};

const CourseRow = ({
  href, imageUrl, description, progress, ctaText,
}) => {
  const {tracker} = useAnalytics();

  return (
    <Link
      href={`${href}?src=dashboard`}
      onClick={() => tracker.dashboardClickLesson({lesson: href})}
      className="flex flex-col justify-between mt-1 -mx-3 lg:items-center lg:flex-row p-3 rounded-lg transition ease-in-out duration-150 hover:bg-gray-50"
    >
      <div className="flex flex-col xs:flex-row xs:items-center">
        <LessonThumbnail imageUrl={imageUrl} progress={progress} className="w-full h-40 mb-3 xs:w-40 xs:h-20 lg:w-60 lg:h-32 xs:mb-0 xs:mr-5" />
        <div className="flex flex-col justify-center mb-3 xs:mr-5 xs:mb-0">
          {description}
        </div>
      </div>
      <Button className="flex-shrink-0 xs:hidden lg:block lg:text-sm lg:px-4">
        {ctaText}
      </Button>
    </Link>
  );
};

const Placeholder = () => (
  <Card className="animate-pulse">
    <div className="space-y-2 mb-4 ">
      <div className="h-4 bg-gray-100 rounded w-20" />
      <div className="h-6 bg-gray-100 rounded xs:w-2/5" />
      <div className="h-4 bg-gray-100 rounded xs:w-1/4" />
    </div>
    <div className="flex flex-col items-center xs:flex-row mb-4">
      <div className="flex-shrink-0 w-full h-40 rounded-md bg-gray-100 mb-5 xs:mb-0 xs:mr-5 xs:w-40 xs:h-20 lg:w-60 lg:h-32" />
      <div className="flex flex-col justify-center space-y-2 w-full">
        <div className="h-4 bg-gray-100 rounded xs:w-2/5" />
        <div className="h-6 bg-gray-100 rounded xs:w-3/5" />
        <div className="h-6 bg-gray-100 rounded xs:w-1/2" />
      </div>
    </div>
    <div className="flex flex-col items-center xs:flex-row mb-4">
      <div className="flex-shrink-0 w-full h-40 rounded-md bg-gray-100 mb-5 xs:mb-0 xs:mr-5 xs:w-40 xs:h-20 lg:w-60 lg:h-32" />
      <div className="flex flex-col justify-center space-y-2 w-full">
        <div className="h-4 bg-gray-100 rounded xs:w-2/5" />
        <div className="h-6 bg-gray-100 rounded xs:w-3/5" />
        <div className="h-6 bg-gray-100 rounded xs:w-1/2" />
      </div>
    </div>
    <div className="flex justify-center">
      <div className="h-4 bg-gray-100 rounded w-1/5" />
    </div>
  </Card>
);

export default CoursesCard;
