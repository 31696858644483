import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {ChecklistItemType} from './DashboardTypes';
import Card from '../../components/Card';
import {Close} from '../../components/Modal';
import Steps from '../../components/Steps';
import KarmaPill from '../../components/KarmaPill';
import {useAppContext, useAnalytics} from '../../hooks';
import {updateCurrentUser} from '../../api/api';

const withKarma = (tracker) => (item) => ({
  ...item,
  body: item.karma && !item.done
    ? (
      <span className="flex items-center w-full">
        {item.body}
        <KarmaPill
          score={item.karma}
          verbose
          onClick={() => tracker.karmaClickScore({source: 'onboarding-checklist'})}
          className="inline-flex ml-5"
        />
      </span>
    ) : item.body,
});

const OnboardingChecklist = ({items, loading}) => {
  const {currentUser, dispatch} = useAppContext();
  const {tracker} = useAnalytics();

  if (loading) return <Placeholder />;

  if (currentUser.data.has_dismissed_checklist) return null;

  const dismissChecklist = () => {
    const data = {...currentUser.data, has_dismissed_checklist: true};
    updateCurrentUser({data});
    dispatch({type: 'dismissChecklist'});
  };

  const itemsWithKarma = items.map(withKarma(tracker));
  const allItemsDone = _.every(items, 'done');

  return (
    <Card className="flex flex-col relative">
      <span className="text-lg font-semibold mb-1">
        Getting started
      </span>
      <span className="text-sm font-medium text-gray-400">
        {allItemsDone ? '🙌 Way to go! You are closer to landing your dream job.' : 'Start preparing for your next interview.'}
      </span>
      <div className="pt-4">
        <Steps items={itemsWithKarma} />
      </div>
      <Close onClick={dismissChecklist} />
    </Card>
  );
};

OnboardingChecklist.propTypes = {
  loading: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(ChecklistItemType).isRequired,
};

const Placeholder = () => (
  <Card>
    <div className="animate-pulse flex space-x-4 w-full">
      <div className="space-y-4 py-1 w-full">
        <div className="h-4 bg-gray-100 rounded w-3/4" />
        <div className="space-y-2">
          <div className="h-4 bg-gray-100 rounded" />
          <div className="h-4 bg-gray-100 rounded w-5/6" />
          <div className="h-4 bg-gray-100 rounded" />
          <div className="h-4 bg-gray-100 rounded w-5/6" />
        </div>
      </div>
    </div>
  </Card>
);

export default OnboardingChecklist;
