/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/button-has-type */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Link from './Link';

export default class Button extends Component {
  render() {
    const {
      children,
      disabled,
      loading,
      onClick,
      className,
      secondary,
      grey,
      uppercase,
      rounded,
      outline,
      href,
      asLink,
      full,
      target,
      type,
      viewEvent,
      style,
    } = this.props;

    const classes = cx({
      Button: true,
      'Button--primary': !secondary,
      'Button--secondary': secondary,
      'Button--grey': grey,
      'Button--uppercase': uppercase,
      'Button--rounded': rounded,
      'Button--full': full,
      'Button--outline': outline,
      disabled,
    }, className);

    const clickEvent = (e) => {
      if (viewEvent) viewEvent();
      if (onClick) onClick(e);
    };

    if (asLink && href) {
      return (
        <Link
          className={classes}
          onClick={clickEvent}
          href={href}
          disabled={disabled || loading}
          target={target}
          style={style}
        >
          <>
            {children}
            {loading && <div className="loader" />}
          </>
        </Link>
      );
    }

    if (href) {
      return (
        <a
          className={classes}
          onClick={clickEvent}
          href={href}
          target={target}
          disabled={disabled || loading}
          style={style}
        >
          {children}
          {loading && <div className="loader" />}
        </a>
      );
    }

    return (
      <button
        type={type}
        className={classes}
        onClick={clickEvent}
        disabled={disabled || loading}
        style={style}
      >
        {children}
        {loading && <div className="loader" />}
      </button>
    );
  }
}

Button.defaultProps = {
  disabled: false,
  loading: false,
  onClick: undefined,
  className: null,
  secondary: false,
  grey: false,
  uppercase: false,
  rounded: false,
  outline: false,
  href: undefined,
  asLink: false,
  full: false,
  target: null,
  viewEvent: undefined,
  type: 'button',
  style: undefined,
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  secondary: PropTypes.bool,
  grey: PropTypes.bool,
  uppercase: PropTypes.bool,
  rounded: PropTypes.bool,
  outline: PropTypes.bool,
  href: PropTypes.string,
  asLink: PropTypes.bool,
  full: PropTypes.bool,
  target: PropTypes.string,
  viewEvent: PropTypes.func,
  type: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};
