import React, {useState} from 'react';
import _ from 'lodash';

import {useAppContext} from '../../hooks';
import Button from '../../components/Button';
import Card from '../../components/Card';
import {Modal} from '../../components/Modal';
import UserProfileSection from './UserProfileSection';

const toProgressString = (progress) => {
  if (progress.includes('progress_interviewing')) return ' interviewing';
  if (progress.includes('progress_applying')) return ' applying';
  if (progress.includes('progress_career')) return ' improving skills';
  return ' exploring';
};

const arrayToString = (arr) => {
  if (arr.length > 2) return ` ${arr[0]} and ${arr.length - 1} more`;
  if (arr.length === 2) return ` ${arr[0]} and ${arr[1]}`;
  if (arr.length === 1) return ` ${arr[0]}`;
  return '';
};

const CareerGoals = ({tags}) => {
  const [showModal, setShowModal] = useState(false);
  const {currentUser} = useAppContext();
  if (!currentUser) return null;

  const toggleModal = () => setShowModal((prev) => !prev);
  const closeModal = () => setShowModal(false);

  const {profile} = currentUser;
  const progress = profile.interview_progress || [];
  const roles = _(profile.career_tracks || [])
    .map((tagId) => tags?.role?.find((r) => r.id === tagId)) // TODO: Avoid doing this?
    .compact()
    .map('name')
    .value();
  const companies = (profile.interview_companies || []).map(_.capitalize);
  const hasSetGoals = progress.length || roles.length || companies.length;

  return (
    <>
      <Modal isOpen={showModal} onClose={closeModal} showCloseButton className="h-full overflow-y-auto max-w-2xl sm:h-5/6">
        <UserProfileSection onSubmit={closeModal} tags={tags} />
      </Modal>
      <Card className="flex flex-col">
        <span className="text-lg font-semibold mb-1">
          My career goals
        </span>
        {hasSetGoals ? (
          <span className="text-sm font-medium text-gray-500">
            {'I\'m'}
            <Highlight>{toProgressString(progress)}</Highlight>
            {progress.includes('progress_interviewing') || progress.includes('progress_applying') ? ' for' : ''}
            <Highlight>{arrayToString(roles)}</Highlight>
            {` roles${companies.length ? ' at' : ''}`}
            <Highlight>{arrayToString(companies)}</Highlight>
            .
          </span>
        ) : (
          <span className="text-sm font-medium text-gray-400 italic">
            Tell us your career goals to get personalized recommendations.
          </span>
        )}
        <div className="pt-4">
          <Button
            outline
            onClick={toggleModal}
            className="text-sm px-4"
          >
            {hasSetGoals ? 'Edit goals' : 'Set goals'}
          </Button>
        </div>
      </Card>
    </>
  );
};

const Highlight = ({children}) => <span className="text-indigo">{children}</span>;

export default CareerGoals;
