import React from 'react';

import Link from '../../components/Link';
import Button from '../../components/Button';
import Card from '../../components/Card';
import {DatabaseIcon} from '../../components/svg';
import {useAnalytics} from '../../hooks';

const QuestionsCard = ({loading = true, questions = []}) => {
  const {tracker} = useAnalytics();

  if (loading) return <Placeholder />;

  return (
    <Card>
      <span className="text-lg font-semibold mb-1">Interview questions</span>
      <span className="block text-gray-500 leading-snug max-w-sm">
        Find and practice with real interview questions asked by companies.
      </span>
      {questions.map((question) => (
        <Link
          href={`${question.url}&src=dashboard`}
          onClick={() => tracker.dashboardClickQuestion({title: question.title, url: question.url})}
          key={question.url}
        >
          <div className="flex justify-between items-center mt-1 -mx-2 p-2 rounded-lg transition ease-in-out duration-150 hover:bg-gray-50">
            <div className="flex">
              <div
                className="flex flex-shrink-0 justify-center items-center h-16 w-16 rounded-md mr-4"
                style={{backgroundColor: question.colors.secondary}}
              >
                <DatabaseIcon className="h-8 w-8" stroke={question.colors.primary} />
              </div>
              <div className="flex flex-col justify-center max-w-xl">
                <span className="block text-base md:text-lg font-medium leading-tight">
                  {question.title}
                </span>
                <span className="block text-gray-500 leading-tight">
                  {`Check out example ${question.title}`}
                </span>
              </div>
            </div>
            <Button outline className="w-30 text-sm flex-shrink-0 hidden lg:block">View Now</Button>
          </div>
        </Link>
      ))}
      <div className="flex justify-center pt-5">
        <Link
          href="/questions?src=dashboard"
          onClick={() => tracker.dashboardClickMoreQuestions()}
          className="text-indigo text-sm font-medium hover:text-indigo-900 focus:text-indigo-900"
        >
          See more questions
        </Link>
      </div>
    </Card>
  );
};

const Placeholder = () => (
  <Card className="animate-pulse">
    <div className="space-y-2 mb-4 ">
      <div className="h-6 bg-gray-100 rounded w-1/4" />
      <div className="h-6 bg-gray-100 rounded w-3/5" />
    </div>
    <div className="flex mb-4">
      <div className="flex-shrink-0 h-16 w-16 rounded-md bg-gray-100 mr-5" />
      <div className="flex flex-col justify-center space-y-2 w-full">
        <div className="h-4 bg-gray-100 rounded w-3/5" />
        <div className="h-6 bg-gray-100 rounded w-4/5" />
      </div>
    </div>
    <div className="flex">
      <div className="flex-shrink-0 h-16 w-16 rounded-md bg-gray-100 mr-5" />
      <div className="flex flex-col justify-center space-y-2 w-full">
        <div className="h-4 bg-gray-100 rounded w-2/5" />
        <div className="h-6 bg-gray-100 rounded w-4/5" />
      </div>
    </div>
  </Card>
);

export default QuestionsCard;
