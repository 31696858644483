import React from 'react';
import cx from 'classnames';

export default function Card({
  shadow = true, border = false, className, children, ...props
}) {
  return (
    <div className={cx('bg-white rounded-lg px-4 py-5 sm:p-6', {shadow, border}, className)} {...props}>
      {children}
    </div>
  );
}
