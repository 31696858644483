import React, {useState, useEffect} from 'react';

import {getCourses, getTags} from '../api/api';
import {Feature} from '../../../lib/constants/tags';

export const CourseLessonsContext = React.createContext({});

const CourseLessonsProvider = (props) => {
  const [courses, setCourses] = useState([]);
  const [tags, setTags] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentLesson, setCurrentLesson] = useState(null);

  useEffect(() => {
    Promise.all([
      getCourses(),
      getTags({feature: Feature.Courses}),
    ]).then(([result, newTags]) => {
      setCourses(result);
      setTags(newTags);
      setLoading(false);
    });
  }, []);

  const markLastVisit = (lessonSlug, courseSlug, trackSlug) => {
    const courseList = trackSlug
      ? courses.find((c) => c.slug === trackSlug)?.courses
      : courses;

    const course = courseList.find((c) => c.slug === courseSlug);

    // mark within the course
    const lesson = course?.lessons?.find((l) => l.slug === lessonSlug);
    if (lesson) lesson.lastVisit = new Date();

    // mark within the module
    course?.modules?.forEach((m) => m.lessons.forEach((l) => {
      // eslint-disable-next-line no-param-reassign
      if (l.slug === lessonSlug) l.lastVisit = new Date();
    }));

    setCourses(courses);
  };

  if (loading && !props.renderWhileLoading) {
    return null;
  }

  return (
    <CourseLessonsContext.Provider
      value={{
        loading,
        courses,
        tags,
        currentLesson,
        setCurrentLesson,
        markLastVisit,
      }}
    >
      {props.children}
    </CourseLessonsContext.Provider>
  );
};

export default CourseLessonsProvider;
