import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {CheckmarkIcon} from './svg';
import Link from './Link';

const Steps = ({items}) => (
  <div>
    <nav className="flex justify-start">
      <ol className="space-y-5">
        {items.map((item) => (
          <li key={item.text}>
            <Link href={item.link ? item.link : '#'} className="group">
              <span className="flex items-start">
                <span className="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
                  {item.done
                    ? <CheckmarkIcon className="h-full w-full text-indigo-600" />
                    : <div className="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400" />}
                </span>
                <span className={cx(
                  'ml-3 text-sm font-medium text-gray-500',
                  {'group-hover:text-gray-900': !item.done},
                )}
                >
                  {item.body}
                </span>
              </span>
            </Link>
          </li>
        ))}
      </ol>
    </nav>
  </div>
);

Steps.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    body: PropTypes.node,
    link: PropTypes.string,
    done: PropTypes.bool,
  })),
};

Steps.defaultProps = {
  items: [],
};

export default Steps;
