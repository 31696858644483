import React, {useState, useEffect} from 'react';
import _ from 'lodash';

import Link from '../../components/Link';
import Button from '../../components/Button';
import Card from '../../components/Card';
import {PlayIcon} from '../../components/svg';
import {useAppContext, useAnalytics} from '../../hooks';
import {getVideoThumbnailURL, clampLines} from '../../util';
import {getRandomQuestion, getCurrentStreak} from '../../api/api';
import {StreakTypes} from '../../constants/streaks';
import Runner from '../../../assets/images/runner.png';
import Trophy from '../../../assets/images/trophy.png';
import {toRelatedCategories} from '../../../../lib/utils';

const DailyChallenge = () => {
  const {currentUser, hasSubscription} = useAppContext();
  const {tracker} = useAnalytics();
  const [question, setQuestion] = useState(null);
  const [streak, setStreak] = useState(0);
  const [loading, setLoading] = useState(true);
  const isVideo = question?.data?.video?.id;

  const shuffle = async () => {
    setLoading(true);

    const roles = currentUser.profile.career_tracks;
    const categories = _.flatMap(roles, toRelatedCategories);

    const searchCriteria = {};
    if (roles?.length) searchCriteria.role = roles;
    if (categories?.length) searchCriteria.type = categories;
    if (!roles?.length && !categories?.length) searchCriteria.type = ['behavioral'];
    searchCriteria.onlyVideo = hasSubscription; // only subscribed members get recommended videos

    let newQuestion = await getRandomQuestion(searchCriteria);
    if (!newQuestion) newQuestion = await getRandomQuestion(); // Relax criteria if no qns returned

    setQuestion(newQuestion);
    setLoading(false);
  };

  useEffect(() => {
    if (currentUser) {
      shuffle();
      getCurrentStreak({type: StreakTypes.DAILY_CHALLENGE}).then(setStreak);
    }
  }, [currentUser]);

  if (loading) return <Placeholder />;
  if (!question) return null;

  const buildQuestionURL = () => {
    const {id, slug} = question;
    let url = `/questions/${id}/${slug}`;

    // If question is a video, Autoplay upon visiting.
    if (hasSubscription) url += '?autoplay=1';

    return url;
  };
  const questionURL = buildQuestionURL();

  const handleCTA = () => (isVideo
    ? tracker.challengeClickWatchVideo({slug: question.slug})
    : tracker.challengeClickAnswerQuestion({slug: question.slug})
  );

  const handleShuffle = () => {
    const track = isVideo
      ? tracker.challengeClickShuffleVideo
      : tracker.challengeClickShuffleQuestion;
    track({slug: question.slug});
    shuffle();
  };

  return (
    <Card className="flex flex-col relative">
      <span className="block text-xs font-semibold mb-1 text-gray-400 uppercase">
        {`${isVideo ? 'Expert video' : 'Question'} of the day`}
      </span>
      <Link href={questionURL} onClick={handleCTA}>
        <h3
          className="text-base font-semibold mb-3 leading-tight"
          style={clampLines(4)}
        >
          {question.data.title}
        </h3>
        {isVideo && (
          <div className="relative flex flex-shrink-0 justify-center items-center mb-3 w-full h-40 rounded-md bg-indigo-100 overflow-hidden group">
            <PlayIcon className="absolute h-6 w-6 transition-all group-hover:h-7 group-hover:w-7" />
            <img src={getVideoThumbnailURL(question.data.video.id)} alt="" className="w-full h-full object-cover" />
          </div>
        )}
      </Link>
      <div className="flex flex-col lg:flex-row gap-2 lg:space-y-0 justify-between mb-4">
        <Button
          asLink
          href={questionURL}
          className="text-sm w-full lg:w-auto px-4 flex-1"
          onClick={handleCTA}
        >
          {isVideo ? 'View now' : 'Answer now'}
        </Button>
        <Button
          outline
          className="text-sm px-4 flex-1"
          onClick={handleShuffle}
        >
          Shuffle
        </Button>
      </div>
      {streak > 0 && (
        <>
          <span className="block text-xs font-semibold mb-2">Your daily streak</span>
          <div className="flex items-center">
            {streak >= 7
              ? <img src={Trophy.src} alt="Trophy" className="w-7/12 mr-4 -ml-4" />
              : <img src={Runner.src} alt="Runner at the finish line" className="w-6/12 mr-5" />}
            <div>
              <div className="text-5xl text-indigo font-medium leading-10">{streak}</div>
              <div className="text-xs font-semibold">Day streak</div>
            </div>
          </div>
        </>
      )}
      <span className="text-sm font-medium text-gray-400">
        {isVideo
          ? 'Watch an expert mock video to stay on top of your interview preparation.'
          : 'Answer a practice question to stay on top of your interview preparation.'}
      </span>
    </Card>
  );
};

const Placeholder = () => {
  const {hasSubscription} = useAppContext();
  return (
    <Card>
      <div className="animate-pulse flex space-x-4 w-full">
        <div className="space-y-4 py-1 w-full">
          <div className="h-4 bg-gray-100 rounded w-3/4" />
          <div className="space-y-2">
            <div className="h-4 bg-gray-100 rounded" />
            <div className="h-4 bg-gray-100 rounded w-5/6" />
            <div className="h-4 bg-gray-100 rounded" />
          </div>
          {hasSubscription && (
            <div className="flex flex-col space-y-2 items-end">
              <div className="w-full h-40 rounded-md bg-gray-100" />
              <div className="h-6 bg-gray-100 rounded w-1/4" />
            </div>
          )}
          <div className="flex justify-between mb-4">
            <div className="h-12 bg-gray-100 rounded mr-5 flex-1" />
            <div className="h-12 bg-gray-100 rounded flex-1" />
          </div>
          <div className="space-y-2">
            <div className="h-4 bg-gray-100 rounded" />
            <div className="h-4 bg-gray-100 rounded w-4/6" />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default DailyChallenge;
