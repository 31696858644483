import React from 'react';
import Plyr from 'plyr-react';

const YouTubeVideo = ({videoID}) => {
  const source = {
    type: 'video',
    sources: [
      {
        src: videoID,
        provider: 'youtube',
      },
    ],
  };

  const options = {
    controls: [
      'play',
      'play-large',
      'progress',
      'duration',
      'mute',
      'volume',
      'captions',
      'settings',
      'fullscreen',
    ],
  };

  return (
    <div style={{'--plyr-color-main': '#5524e0'}}>
      <Plyr
        options={options}
        source={source}
      />
    </div>
  );
};

export default React.memo(YouTubeVideo);
