import React from 'react';
import cx from 'classnames';

import {PlayIcon} from './svg';

const LessonThumbnail = ({imageUrl, progress = 0, className}) => (
  <div className={cx('relative flex flex-shrink-0 justify-center items-center rounded-md bg-indigo-100 overflow-hidden group', className)}>
    <PlayIcon className="absolute h-6 w-6 transition-all group-hover:h-7 group-hover:w-7" />
    {imageUrl && <img src={imageUrl} alt="" className="w-full h-full object-cover" loading="lazy" />}
    {progress > 0 && (
    <div className="absolute flex bottom-0 w-full h-1.5">
      <div className="bg-indigo" style={{width: `${progress}%`}} />
      <div className="bg-gray-300 opacity-40 flex-grow" />
    </div>
    )}
  </div>
);

export default LessonThumbnail;
