import React, {useEffect, useState} from 'react';

import {Modal} from './Modal';
import YouTubeVideo from './YouTubeVideo';
import {useAnalytics, useAppContext} from '../hooks';
import {updateCurrentUser} from '../api/api';

const VIDEO_ID = 'BpRJXHobQ0Y';

export default function OnboardingVideo({isOpen, onClose}) {
  const {currentUser, dispatch} = useAppContext();
  const {tracker} = useAnalytics();

  const [played, setPlayed] = useState(false);

  const onClickPlay = () => {
    if (!played) {
      tracker.dashboardPlayOnboardingVideo();
      setPlayed(true);
    }
  };

  const onClickClose = () => {
    tracker.dashboardCloseOnboardingVideo({played});
    if (onClose) onClose();

    updateCurrentUser({data: {...currentUser.data, has_seen_onboarding_video: true}});
    dispatch({type: 'seenOnboardingVideo'});
  };

  useEffect(() => {
    if (isOpen) {
      tracker.dashboardViewOnboardingVideo();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <Modal
      className="max-w-2xl p-4"
      isOpen={isOpen}
      onClose={onClickClose}
      showCloseButton
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
    >
      <span className="text-2xl font-semibold text-black text-center mt-2 mb-4">
        Welcome to Exponent!
      </span>
      <button type="button" onClick={onClickPlay} className="w-full appearance-none" aria-label="Play video">
        <YouTubeVideo videoID={VIDEO_ID} />
      </button>
    </Modal>
  );
}
