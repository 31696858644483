import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import _ from 'lodash';
import cx from 'classnames';

import CloseButton from './CloseButton';
import {Spinner} from './Spinner';

export const Modal = (props) => {
  const {
    isOpen,
    onClose,
    className,
    children,
    showCloseButton,
    shouldCloseOnOverlayClick,
    shouldCloseOnEsc,
    disableCloseButton,
  } = props;

  return (
    <ReactModal
      overlayClassName="Modal__overlay"
      className={cx('Modal', className)}
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
    >
      {children}
      {showCloseButton && <Close onClick={onClose} disabled={disableCloseButton} />}
    </ReactModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  shouldCloseOnOverlayClick: PropTypes.bool,
};

Modal.defaultProps = {
  children: null,
  className: null,
  onClose: null,
  shouldCloseOnOverlayClick: true,
};

export const Close = ({className = '', onClick = _.noop, disabled = false}) => (
  <CloseButton
    className={cx(`
      absolute 
      top-4 
      right-4
      w-5
    `,
    className)}
    onClick={onClick}
    disabled={disabled}
  />
);

export const Header = (props) => (
  <div className="Modal__header">
    {props.children}
  </div>
);

export const Body = ({className, children}) => {
  const classes = cx('Modal__body', className);
  return (
    <div className={classes}>
      {children}
    </div>
  );
};

export const Footer = ({className, children}) => {
  const classes = cx('Modal__footer', className);

  return (
    <div className={classes}>
      {children}
    </div>
  );
};

export const Button = ({
  className, children, onClick, loading,
}) => {
  const classes = cx(`
  Modal__button
  rounded 
  px-3 
  py-2 
  font-semibold 
  cursor-pointer
  `, {
    [className]: className,
    [`
    relative
    disabled
    `]: loading,
  });

  if (loading) {
    return (
      <button
        disabled
        className={classes}
        type="button"
      >
        {children}
        <div className={`
        absolute
        top-0
        left-0
        right-0
        rounded
        height-full
        bg-inherit
        pt-2
        `}
        >
          <Spinner className={`
          border-4 
          h-5 
          w-5
          `}
          />
        </div>
      </button>
    );
  }

  return (
    <button
      className={classes}
      type="button"
      onClick={onClick || (() => { })}
    >
      {children}
    </button>
  );
};

export const Actions = (props) => (
  <div className="Modal__actions">
    {props.children}
  </div>
);

Header.propTypes = {
  children: PropTypes.node.isRequired,
};

Body.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Body.defaultProps = {
  className: '',
};

Footer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Footer.defaultProps = {
  className: '',
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

Button.defaultProps = {
  className: '',
  loading: false,
};

Actions.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Modal;
