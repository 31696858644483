import React from 'react';
import {Formik, Form} from 'formik';
import _ from 'lodash';

import CareerProgressForm from '../../components/onboarding/CareerProgressForm';
import RolesForm from '../../components/onboarding/RolesForm';
import CompaniesForm from '../../components/onboarding/CompaniesForm';
import SubmitButton from '../../components/onboarding/SubmitButton';
import {useAppContext} from '../../hooks';
import {updateUserProfile} from '../../api/api';
import {withParentTags} from '../../../../lib/utils';

const UserProfileSection = ({onSubmit = _.noop, tags}) => {
  const {currentUser, dispatch} = useAppContext();

  const publish = async (update) => {
    let allCompanies = update.companies;
    if (update.others.length) {
      const cleanedOtherValues = update.others.map((o) => o.trim().toLowerCase());
      allCompanies = _.union(allCompanies, cleanedOtherValues);
    }

    const updatedProfile = await updateUserProfile({
      career_tracks: withParentTags(update.roles, tags),
      interview_progress: update.career_progress,
      interview_companies: allCompanies,
    });
    dispatch({type: 'updateUserProfile', payload: updatedProfile});
    onSubmit();
  };

  const getInitialValues = () => {
    const {profile} = currentUser;
    const initialValues = {
      roles: profile.career_tracks || [],
      career_progress: profile.interview_progress || [],
      query: '',
    };

    if (profile.interview_companies) {
      const [knownCompanies, others] = _.partition(
        profile.interview_companies,
        (c) => tags?.company?.map((companyTag) => companyTag.id).includes(c),
      );
      initialValues.companies = knownCompanies;
      initialValues.others = others;
    } else {
      initialValues.companies = [];
      initialValues.others = [];
    }

    return initialValues;
  };

  return (
    <div className="flex flex-col items-center m-6 mt-12">
      <Formik
        initialValues={getInitialValues()}
        onSubmit={publish}
      >
        <Form>
          <Section>
            <SectionHeader>{CareerProgressForm.title}</SectionHeader>
            <CareerProgressForm.Fields tags={tags} />
          </Section>
          <Section>
            <SectionHeader>{RolesForm.title}</SectionHeader>
            <RolesForm.Fields tags={tags} />
          </Section>
          <Section>
            <SectionHeader>{CompaniesForm.title}</SectionHeader>
            <CompaniesForm.Fields tags={tags} name="companies" />
          </Section>
          <div className="flex flex-col items-end w-full px-4">
            <SubmitButton>Submit</SubmitButton>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

const SectionHeader = ({children}) => <h3 className="text-2xl font-bold text-gray-700 mb-8">{children}</h3>;

const Section = (props) => <div className="mb-8 pt-8 border-t border-gray-200 sm:mx-4 first:border-0 first:pt-0" {...props} />;

export default UserProfileSection;
