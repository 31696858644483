import React, {useState, useEffect} from 'react';
import {useRouter} from 'next/router';

import OnboardingChecklist from './OnboardingChecklist';
import CareerGoals from './CareerGoals';
import DailyChallenge from './DailyChallenge';
import CoursesCard from './CoursesCard';
import QuestionsCard from './QuestionsCard';
import PracticeCard from './PracticeCard';
import CourseLessonsProvider from '../../components/CourseLessonsProvider';
import {Grid, Row, Col} from '../../components/Grid';
import DocumentHeader from '../../components/DocumentHeader';
import OnboardingVideo from '../../components/OnboardingVideo';
import {useAppContext, useAnalytics} from '../../hooks';
import {
  createURLWithParams,
  isUsersFirstDay,
  tagsToQuestionListTitle,
  shouldShowOnboardingVideo,
  shouldHideCoaching,
} from '../../util';
import {
  getOnboardingChecklist,
  getRecommendations,
  getMostRecentLesson,
  getTags,
} from '../../api/api';
import {Feature} from '../../../../lib/constants/tags';

const QuestionIconColors = [
  {primary: '#047857', secondary: '#D1FAE5'},
  {primary: '#F59E0B', secondary: '#FEF3C7'},
];

const buildHeader = (currentUser) => {
  let header = 'Welcome';
  if (!isUsersFirstDay(currentUser)) header += ' back';
  if (currentUser?.first_name) header += `, ${currentUser?.first_name}`;
  header += '!';
  return header;
};

const DashboardPage = () => {
  const {currentUser, features} = useAppContext();
  const {tracker} = useAnalytics();
  const {query} = useRouter();
  const [tags, setTags] = useState(null);
  const [mostRecentLesson, setMostRecentLesson] = useState(null);
  const [checklist, setChecklist] = useState({loading: true, items: []});
  const [videoOpen, setVideoOpen] = useState(false);
  const [recommendations, setRecommendations] = useState({
    loading: true,
    courses: [],
    questions: [],
    coaches: [],
  });

  useEffect(() => {
    if (!currentUser) return;
    getOnboardingChecklist(currentUser.uuid).then((items) => setChecklist({loading: false, items}));

    Promise.all([
      getRecommendations(),
      getMostRecentLesson(),
      getTags({feature: Feature.Profile}),
    ]).then(([recs, lesson, tagResults]) => {
      const questionsWithMetadata = recs.questions.map((question, i) => ({
        ...question,
        colors: QuestionIconColors[i % QuestionIconColors.length],
        title: tagsToQuestionListTitle(tagResults, question.params, true),
        url: createURLWithParams('/questions', question.params, {arrayFormat: 'comma'}),
      }));
      const coursesWithMetadata = recs.courses.map((course) => ({
        ...course,
        isTrack: Boolean(course.courses),
      }));
      setRecommendations({
        loading: false,
        courses: coursesWithMetadata,
        questions: questionsWithMetadata,
        coaches: recs.coaches,
      });
      setMostRecentLesson(lesson);
      setTags(tagResults);
    });

    tracker.viewDashboard({
      features,
      companies: currentUser.profile.interview_companies || [],
      progress: currentUser.profile.interview_progress || [],
      roles: currentUser.profile.career_tracks || [],
    });
  }, [currentUser]);

  // Onboarding video
  useEffect(() => {
    const showVideo = shouldShowOnboardingVideo(currentUser) || query.showVideo === 'true';
    setVideoOpen(showVideo);
  }, []);

  return (
    <CourseLessonsProvider>
      <DocumentHeader title="Dashboard" />
      <OnboardingVideo isOpen={videoOpen} onClose={() => setVideoOpen(false)} />
      <div className="mx-auto max-w-6xl px-4 py-6">
        <Grid>
          <Row>
            <Col>
              <h1 className="block ml-0.5 mt-2 mb-1 text-xl text-center font-bold text-gray-900 md:text-4xl md:text-left">
                {buildHeader(currentUser)}
              </h1>
              <h2 className="block w-full ml-1 mb-6 text-sm text-center font-medium leading-tight text-gray-500 md:w-2/3 md:text-base md:text-left">
                {isUsersFirstDay(currentUser)
                  ? 'Check out our recommended plan to ace your interviews.'
                  : 'Continue learning with our recommendations based on your career goals and recent activity.'}
              </h2>
            </Col>
          </Row>
          <Row className="flex-wrap-reverse">
            <Col md={4} className="space-y-6 mb-6">
              <OnboardingChecklist
                loading={checklist.loading}
                items={checklist.items}
              />
              <DailyChallenge />
              <CareerGoals tags={tags} />
            </Col>
            <Col md={8} className="space-y-6 mb-6">
              <CoursesCard
                loading={recommendations.loading}
                courses={recommendations.courses}
                suggestedLesson={mostRecentLesson}
              />
              <QuestionsCard
                loading={recommendations.loading}
                questions={recommendations.questions}
              />
              {!shouldHideCoaching(currentUser) && (
                <PracticeCard
                  loading={recommendations.loading}
                  coaches={recommendations.coaches}
                />
              )}
            </Col>
          </Row>
        </Grid>
      </div>
    </CourseLessonsProvider>
  );
};

export default DashboardPage;
